<template>
  <div class="HomePage">
    <div class="box">
      <!-- <div class="search_box">
        <div class="search">
          <div class="search_left">
            <el-select
              @change="selectChange"
              v-model="selectTheme"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <input
            class="inp"
            v-model="searchValue"
            type="text"
            placeholder="让每个人都写得更好"
            @keyup.enter="search"
          />
          <div class="search_btn" @click="search">
            <i class="search_icon"></i>
            <img :src="require('assets/images/idiom.png')" alt="" />
            <img :src="require('assets/images/included_search.png')" alt="" />
          </div>
        </div>

        <div class="key_box">
          <div
            class="key_item"
            :class="keyIndex == index ? 'keyI' : ''"
            @click="keyClick(index)"
            v-for="(item, index) in keyList"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
      </div> -->

      <div class="content_box">
        <div class="left">
          <div class="top">
            <!-- 侧边栏 -->
            <!-- <div class="sidebar">
              <div class="title">热点文献</div>
              <el-menu :default-openeds="['1']">
                <el-submenu
                  v-for="(item, index) in sidebarList"
                  :key="index"
                  :index="item.index"
                >
                  <template slot="title">{{ item.name }} </template>

                  <el-menu-item
                    v-for="(item1, index1) in item.children"
                    :key="index1"
                    :index="item1.labelId"
                    @click.native.stop="menuClick(item1.labelId, index)"
                  >
                    {{ item1.conName }}</el-menu-item
                  >
                </el-submenu>
              </el-menu>
            </div> -->

            <!-- 轮播图 -->
            <div class="banner">
              <!-- <img
                :src="require('assets/images/hamePage/banner1.png')"
                alt=""
              /> -->

              <el-carousel
                height="470px"
                :interval="5000"
                trigger="click"
                arrow="hover"
              >
                <el-carousel-item
                  v-for="(item, index) in bannerList"
                  :key="index"
                >
                  <img
                    @click="carouselClick(index)"
                    class="img"
                    :src="item.imgUrl"
                    alt=""
                  />
                </el-carousel-item>
              </el-carousel>
            </div>

            <!-- 视频列表 -->
            <div class="video">
              <div class="text">热点</div>

              <div class="img_box">
                <img
                  @click="
                    $router.push({
                      name: 'TwentyBig',
                    })
                  "
                  :src="require('assets/images/hamePage/img_box1.png')"
                  alt=""
                />
              </div>
              <div class="img_box">
                <img
                  @click="freeGet"
                  :src="require('assets/images/hamePage/img_box2.png')"
                  alt=""
                />
              </div>
              <div class="img_box">
                <img
                  @click="invitation"
                  :src="require('assets/images/hamePage/img_box3.png')"
                  alt=""
                />
              </div>

              <!-- <div
                class="video_item"
                @click="
                  handleVideo(
                    require('assets/images/hamePage/vidbgc1.png'),
                    'https://www.writemall.com/download/成语极速版智能推文.mp4'
                  )
                "
              >
                <img
                  :src="require('assets/images/hamePage/vidbgc1.png')"
                  alt=""
                />

                <div class="video_title">智能推文</div>

                <div class="video_related">
                  <div class="heat">
                    <i class="heat_icon" />
                    1
                  </div>
                  <div class="score">
                    <i class="score_icon" />
                    2
                  </div>
                  <div class="review">
                    <i class="score_icon" />
                    3
                  </div>
                </div>
              </div>
              <div
                class="video_item"
                @click="
                  handleVideo(
                    require('assets/images/hamePage/vidbgc2.png'),
                    'https://www.writemall.com/download/成语极速版·智能推图.mp4'
                  )
                "
              >
                <img
                  :src="require('assets/images/hamePage/vidbgc2.png')"
                  alt=""
                />

                <div class="video_title">智能推图</div>

                <div class="video_related">
                  <div class="heat">
                    <i class="heat_icon" />
                    1
                  </div>
                  <div class="score">
                    <i class="score_icon" />
                    2
                  </div>
                  <div class="review">
                    <i class="score_icon" />
                    3
                  </div>
                </div>
              </div> -->
            </div>
          </div>

          <div class="botton">
            <img :src="require('assets/images/hamePage/bgc1.png')" alt="" />

            <div class="btn" @click="handleJSDownLoad">
              <img :src="require('assets/images/jishuWhite.png')" alt="" />
              Windows系统版本下载
            </div>
          </div>

          <div class="info_box">
            <div
              class="info_item"
              v-for="(item, index) in infoList"
              :key="index"
            >
              <div class="left_box">
                <img class="img" :src="item.titSrc" alt="" />

                <div class="title">{{ item.title }}</div>

                <div class="text">
                  {{ item.text }}
                </div>
              </div>
              <div
                class="right_box"
                @click="handleVideo(item.imgSrc, item.videoSrc)"
              >
                <!-- <videoPlayerPage
                  v-if="item.videoSrc"
                  :imgSrc="item.imgSrc"
                  :videoSrc="item.videoSrc"
                /> -->

                <img class="img" :src="item.imgSrc" alt="" />

                <img
                  class="Start"
                  v-if="item.videoSrc"
                  :src="require('assets/Start.png')"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="Recommend_text">推荐阅读</div>

          <div class="article_box">
            <div class="item" @click="menuClick('SZfalfg00001-0007-0001', 0)">
              宪法
            </div>
            <div
              class="item"
              @click="menuClick('SZdangnfg00001-0001-0001-0003-0001', 0)"
            >
              中国共产党章程
            </div>
            <div
              class="item"
              @click="menuClick('SZzhongdhy00001-0005-0033', 0)"
            >
              党的二十大报告
            </div>

            <div
              class="article_item"
              @click="
                $router.push({
                  name: 'showData',
                })
              "
            >
              <div class="title">数据概览</div>

              <div class="item">
                今日新增 <span>{{ allData.allContentNumToday }}</span> 篇
              </div>
              <div class="item">
                总量 <span>{{ allData.allContentNum }}</span> 篇
              </div>
              <div class="item">
                算法调用
                <span>{{
                  (allData.recommend || 0) +
                  (allData.pictureAI || 0) +
                  (allData.screenshotOCR || 0)
                }}</span>
                次
              </div>
            </div>

            <div
              class="article_item"
              v-for="(item, index) in articleList"
              :key="index"
            >
              <div class="title">{{ item.title }}</div>

              <div
                class="item"
                @click="articleClick(item1.md5Label)"
                :title="item1.name"
                v-for="(item1, index1) in item.children"
                :key="index1"
              >
                {{ item1.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="data_box">
      <div class="item_box">
        <div class="name">今日新增</div>
        <div class="article">
          <span class="count">{{ allData.allContentNumToday }}</span
          ><span class="unit">篇</span>
        </div>
        <div class="infoCompile dot">文库 {{ allData.libraryNumToday }}篇</div>
        <div class="stencil dot">模板 {{ allData.templateNumToday }}篇</div>
        <div class="goldenSentence dot">金句 {{ allData.goldNumToday }}条</div>

        <img
          class="bg"
          :src="require('assets/images/showData/bg1.png')"
          alt=""
        />
      </div>

      <div class="item_box">
        <div class="name">数据总量</div>
        <div class="article">
          <span class="count">{{ allData.allContentNum }}</span
          ><span class="unit">篇</span>
        </div>
        <div class="infoCompile dot">文库 {{ allData.libraryNum }}篇</div>
        <div class="stencil dot">模板 {{ allData.templateNum }}篇</div>
        <div class="goldenSentence dot">金句 {{ allData.goldNum }}条</div>

        <img
          class="bg"
          :src="require('assets/images/showData/bg2.png')"
          alt=""
        />
      </div>

      <div class="item_box">
        <div class="name">算法引擎调用总量</div>
        <div class="article">
          <span class="count">{{
            (allData.recommend || 0) +
            (allData.pictureAI || 0) +
            (allData.screenshotOCR || 0)
          }}</span
          ><span class="unit">次</span>
        </div>
        <div class="infoCompile dot">
          智能推文 {{ allData.recommend || 0 }}次
        </div>
        <div class="stencil dot">AI成图 {{ allData.pictureAI || 0 }}次</div>
        <div class="goldenSentence dot">
          截图OCR {{ allData.screenshotOCR || 0 }}次
        </div>

        <img
          class="bg"
          :src="require('assets/images/showData/bg3.png')"
          alt=""
        />
      </div>
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>

    <el-dialog :visible.sync="dialogVisible" :before-close="beforeClose">
      <videoPlayerPage v-if="videoSrc" :imgSrc="imgSrc" :videoSrc="videoSrc" />
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import qs from "qs";
import { getEsDateNum, check } from "api/common";
import commonFooter from "components/footer/commonFooter.vue";
import videoPlayerPage from "components/videoInfoComponents/videoPlayerPage.vue";
import { getToken } from "utils/auth.js";
import { getlibrarylist } from "api/dropDownList";
export default {
  name: "HomePage",
  //import引入的组件需要注入到对象中才能使用
  components: {
    commonFooter,
    videoPlayerPage,
  },
  data() {
    //这里存放数据
    return {
      allData: {},
      imgSrc: "",
      videoSrc: "",
      selectTheme: "1",
      options: [
        {
          value: "1",
          label: "文库",
        },
        {
          value: "2",
          label: "模板",
        },

        {
          value: "3",
          label: "金句",
        },
      ],
      // 关键字
      searchValue: "",
      dialogVisible: false,
      keyList: [
        {
          name: "VIP/SVIP会员",
        },
        {
          name: "字符包",
        },
        {
          name: "AI绘画",
        },
      ],

      bannerList: [
        {
          imgUrl: require("assets/images/hamePage/banner1.png"),
        },
        {
          imgUrl: require("assets/images/hamePage/banner2.png"),
        },
        {
          imgUrl: require("assets/images/hamePage/banner3.png"),
        },
        {
          imgUrl: require("assets/images/hamePage/banner4.png"),
        },
        {
          imgUrl: require("assets/images/hamePage/banner5.png"),
        },
        {
          imgUrl: require("assets/images/hamePage/banner6.png"),
        },
      ],

      keyIndex: 0,

      infoList: [
        {
          imgSrc: require("assets/images/hamePage/videobgc1.png"),
          titSrc: require("assets/images/hamePage/titimg1.png"),
          title: "智能推文",
          text: "根据鼠标选中或者输入的文本，精准匹配30余种中文语境算法引擎，实时智能提供优选内容方案，一键复制进编辑框，大幅降低创作难度、激发创作灵感。（隐藏功能：使用成语Office/WPS版，采纳推荐内容时可以自动插入规范的脚注尾注，大学论文写作神器）",
          videoSrc: "https://www.writemall.com/download/成语极速版智能推文.mp4",
        },
        {
          imgSrc: require("assets/images/hamePage/videobgc2.png"),
          titSrc: require("assets/images/hamePage/titimg2.png"),
          title: "智能推图",
          text: "制作PPT材料时，“智能推图”可以根据选中的内容推荐站内站外的图片；（隐藏功能：聊天斗图，站内站外粘贴复制立即可用）",
          videoSrc:
            "https://www.writemall.com/download/成语极速版·智能推图.mp4",
        },
        {
          imgSrc: require("assets/images/hamePage/videobgc3.png"),
          titSrc: require("assets/images/hamePage/titimg3.png"),
          title: "AI绘画",
          text: "当灵感匮乏时，可以输入指令，驱动AI智能创造独一无二的原创画作；（隐藏功能：独特、有趣、解压的绘画创作）",
          videoSrc: "",
        },
        {
          imgSrc: require("assets/images/hamePage/videobgc4.png"),
          titSrc: require("assets/images/hamePage/titimg4.png"),
          title: "网页采集",
          text: "找资料、浏览网页时，使用“网页采集”功能，可以将当前网页转化为word文档后存入自带的网盘库，并可以作为智能推文的语境库（隐藏功能：一键在手，天下文章我有）",
          videoSrc: "",
        },
        {
          imgSrc: require("assets/images/hamePage/videobgc5.png"),
          titSrc: require("assets/images/hamePage/titimg5.png"),
          title: "文库",
          text: "全面汇聚领导人时事新闻、重大会议、新闻联播、新闻发布、党内法规、法律法规、工作报告、战略规划、政策文件、裁判文书、执法文书等十余类专业文献，这些文档都是word版本，可以随时随地查看、下载、分享。更有公文写作、基层党建、个人文稿、法律文书、合同协议、机关制度、活动策划、节日庆典、大学生类、教师专用、文秘专用、公司管理、企业制度、商业计划书等优秀模板。以及公文写作、儒家经典、中小学作文等闪亮金句。（隐藏功能：有个“汇编成册”的独特功能，将想要的文档添加汇编，点击汇编成册则可以将多篇文档汇编成为自带目录的一个文档）",
          videoSrc: "",
        },
        {
          imgSrc: require("assets/images/hamePage/videobgc6.png"),
          titSrc: require("assets/images/hamePage/titimg6.png"),
          title: "截图取字",
          text: "当想要将网页上不可复制的内容粘贴到文档中，或者碰到不可编辑的宣传册等PDF材料时，“截图取字”可以将截图区域中的文字识别转化为可编辑的文本，抛弃传统做法，无需下载转文本，毫秒级无死角、超高精确度，想取哪里截哪里",
          videoSrc: "",
        },
      ],

      sidebarList: [
        {
          name: "文库",
          index: "1",
          children: [],
        },
        {
          name: "金句",
          index: "2",
          children: [],
        },
        {
          name: "模板",
          index: "3",
          children: [],
        },
      ],

      articleList: [
        {
          title: "公文写作",
          children: [
            {
              name: "二十大报告应知会知识点（原创）",
              md5Label: "8576de1e2e3f1fccb05628c1d030e674/MBxuexddesdjs00001",
            },
            {
              name: "党的二十大报告学习心得体会",
              md5Label: "f538af3d97e8d78ccfc4d9334bef36bd/MBxuexddesdjs00001",
            },
            {
              name: "机关党支部2022年度组织生活会对照检查材料",
              md5Label: "0f06a1a091838ae658cf9b78b60802e7/MBxuexddesdjs00001",
            },
            {
              name: "领导班子2022年民主生活会对照检查材料",
              md5Label: "b76be42b6c5732c583aa9a2902c42266/MBgongwxz00001",
            },
            {
              name: "县工业园管委会2022年工作情况汇报",
              md5Label: "f1263278e19f5931fe87c08118e99e4a/MBgongwxz00001",
            },
          ],
        },
        // {
        //   title: "第一议题学习",
        //   children: [
        //     {
        //       name: "习近平：高举中国特色社会主义伟大旗帜 为全面建设社会主义现代化国家而团结奋斗",
        //       md5Label: "dad077ea4a976767815ac185dfae182e/WKlingdrssx00001",
        //     },
        //     {
        //       name: "新时代中国共产党的历史使命",
        //       md5Label: "f710b3c2747535d48dcd18cc69074773/WKlingdrssx00001",
        //     },
        //     {
        //       name: "新时代十年的伟大变革具有里程碑意义",
        //       md5Label: "a6c8af9a120fea532a243249185d72c7/WKlingdrssx00001",
        //     },
        //     {
        //       name: "以中国式现代化全面推进中华民族伟大复兴",
        //       md5Label: "24f13c42edc913b2968ede398e5cb42f/WKlingdrssx00001",
        //     },
        //     {
        //       name: "加快构建新发展格局，着力推动高质量发展",
        //       md5Label: "684c67eca69e8f66a8fa099c211456b4/WKlingdrssx00001",
        //     },
        //   ],
        // },
        {
          title: "法律文书",
          children: [
            {
              name: "民事起诉书",
              md5Label: "734bd742f49b6425502b5666142d95d2/MBxuexddesdjs00001",
            },
            {
              name: "民事上诉状",
              md5Label: "63fd3559af6be1e55a6be2bcb41047d6/MBxuexddesdjs00001",
            },
            {
              name: "民事调解书（一审民事案件用）",
              md5Label: "b2405cc97fe2bc207cf7cddcc874f21a/MBxuexddesdjs00001",
            },
            {
              name: "人民检察院起诉书",
              md5Label: "84c71765a4094687897fc6bab31dca38/MBxuexddesdjs00001",
            },
            {
              name: "上诉答辩书",
              md5Label: "fcf087d46a7ea1d0dcec4c4bb01801a5/MBxuexddesdjs00001",
            },
          ],
        },
        {
          title: "党内法规",
          children: [
            {
              name: "关于新形势下党内政治生活的若干准则",
              md5Label: "6eb076cfa0bd6f6b062e3cde6db3e570/WKlingdrssx00001",
            },
            {
              name: "中国共产党政治协商工作条例",
              md5Label: "e07be56420f8453d736af774ad3d0b37/WKlingdrssx00001",
            },
            {
              name: "事业单位领导人员管理规定",
              md5Label: "cc0fc6c273ce361ef984b283c0dffd43/WKlingdrssx00001",
            },
            {
              name: "创建示范活动管理办法（试行）",
              md5Label: "9d85755d51611c673cd31eef78aebd62/WKlingdrssx00001",
            },
            {
              name: "纪检监察机关派驻机构工作规则",
              md5Label: "11f96b3d278ae47a0eda0258f7a83003/WKlingdrssx00001",
            },
          ],
        },
        {
          title: "法律法规",
          children: [
            {
              name: "中华人民中国刑法",
              md5Label: "de9743144fd9cb4309d620691937bd98/WKlingdrssx00001",
            },
            {
              name: "中华人民共和国民法典",
              md5Label: "9e3d9a5a4a7b8a7ed87bbc03f113751c/WKlingdrssx00001",
            },
            {
              name: "中华人民共和国体育法",
              md5Label: "466f8f9e72af4c365a095c9241d212c6/WKlingdrssx00001",
            },
            {
              name: "中华人民共和国反电信网络诈骗法",
              md5Label: "900dc16099a367cf94a79ca71bdbc075/WKlingdrssx00001",
            },
            {
              name: "中华人民共和国妇女权益保障法",
              md5Label: "444cbe70ccbfbec055c1dd262de8989a/WKlingdrssx00001",
            },
          ],
        },

        {
          title: "政策文件",
          children: [
            {
              name: "国务院关于国家公园空间布局方案的批复",
              md5Label: "912a16a94c53eea405d9b95b1e636177/WKlingdrssx00001",
            },
            {
              name: "缔结条约管理办法",
              md5Label: "25c59a99e228bfa5e127d8cac0194c8f/WKlingdrssx00001",
            },
            {
              name: "国务院办公厅关于印发全国一体化政务大数据体系建设指南的通知",
              md5Label: "2a7ee65f7f6260ab6067e438d3a6538f/WKlingdrssx00001",
            },
            {
              name: "国务院办公厅关于印发第十次全国深化“放管服”改革电视电话会议重点任务分工方案的通知",
              md5Label: "f696201fb830384179e2fb5dca8eea76/WKlingdrssx00001",
            },

            {
              name: "国务院办公厅关于复制推广营商环境创新试点改革举措的通知",
              md5Label: "a2be48b09c9f707fcc0860430fa1afca/WKlingdrssx00001",
            },
          ],
        },
        {
          title: "战略规划",
          children: [
            {
              name: "中华人民共和国国民经济和社会发展第十四个五年规划和2035年远景目标纲要",
              md5Label: "51a3af47f8983e348ed62fc0828f24f9/WKlingdrssx00001",
            },
            {
              name: "关于印发“十四五”新型城镇化实施方案的通知",
              md5Label: "ca66e090d83c12f2ebc4b579607358dc/WKlingdrssx00001",
            },
            {
              name: "北京市人民政府关于印发《北京市“十四五”时期交通发展建设规划》的通知",
              md5Label: "0d083b6e14c297c8ec84219761cc970b/WKlingdrssx00001",
            },
            {
              name: "重庆市人民政府关于印发重庆市国民经济和社会发展第十四个五年规划和二〇三五年远景目标纲要的通知",
              md5Label: "b76be42b6c5732c583aa9a2902c42266/WKlingdrssx00001",
            },
            {
              name: "浙江省国民经济和社会发展第十四个五年规划和二〇三五年远景目标纲要",
              md5Label: "96e91352890d7e44937bcaf10edf7cda/WKlingdrssx00001",
            },
          ],
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getCount() {
      const res = await getEsDateNum();
      this.allData = res.data;
    },
    handleJSDownLoad() {
      const link = document.createElement("a");
      link.href = "https://qiniu.writemall.com/ChengyuSpeedEdition/%E6%88%90%E8%AF%AD%E6%9E%81%E9%80%9F%E7%89%88%28windows%E7%B3%BB%E7%BB%9F%29.zip";
      link.download = "download";
      link.click();
    },
    selectChange() {},
    handleVideo(img, video) {
      if (video != "") {
        this.imgSrc = img;
        this.videoSrc = video;
        this.dialogVisible = true;
      }
    },
    beforeClose() {
      this.imgSrc = "";
      this.videoSrc = "";
      this.dialogVisible = false;
    },
    // 搜索
    search() {
      if (this.selectTheme == 1) {
        this.$bus.$emit("changeNavIndex", "文库");
        this.$router.push({
          name: "InfoCompileIndex",
          params: {
            searchValue: this.searchValue,
          },
        });
      }
      if (this.selectTheme == 2) {
        this.$bus.$emit("changeNavIndex", "模板");
        this.$router.push({
          name: "templatetHtml",
          params: {
            searchValue: this.searchValue,
          },
        });
      }
      if (this.selectTheme == 3) {
        this.$bus.$emit("changeNavIndex", "金句");
        this.$router.push({
          name: "GoldenSentenceIndex",
          params: {
            searchValue: this.searchValue,
          },
        });
      }
    },
    // 关键字点击
    keyClick(index) {
      this.keyIndex = index;
      if (index > 1) {
        this.$router.push({
          name: "details",
          params: {
            id: 972,
          },
        });
      } else {
        this.$router.push({
          name: "details",
          params: {
            id: index + 1,
          },
        });
      }
    },

    articleClick(articleClick) {
      this.$bus.$emit("changeNavIndex", "文库");
      this.$router.push({
        name: "InfoCompileIndex",
        params: {
          articleClick,
        },
      });
    },

    invitation() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });
        return window.loginFun();
      }
      this.$bus.$emit("showInvitationFirend");
    },

    carouselClick(index) {
      // console.log(index);
      if (index === 4) {
        this.$router.push("/downloadGuide");
      } else if (index === 5) {
        this.$router.push("/VIPcenter");
      }
    },

    async freeGet() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });
        return window.loginFun();
      }
      this.$bus.$emit("showFreeGetVip");
      // const res = await check();
      // if (res.data) {
      //   return this.$message("");
      // } else {

      // }
    },

    menuClick(labelId, index) {
      if (index === 0) {
        this.$bus.$emit("changeNavIndex", "文库");
        this.$router.push({
          name: "InfoCompileIndex",
          params: {
            labelId,
          },
        });
      }
      if (index === 1) {
        this.$bus.$emit("changeNavIndex", "金句");
        this.$router.push({
          name: "GoldenSentenceIndex",
          params: {
            labelId,
          },
        });
      }
      if (index === 2) {
        this.$bus.$emit("changeNavIndex", "模板");
        this.$router.push({
          name: "templatetHtml",
          params: {
            labelId,
          },
        });
      }
    },

    // 获取侧边栏数据
    async getSidebarList(parentId, index) {
      const data = {
        labelId: "",
        parentId,
      };

      const res = await getlibrarylist(qs.stringify(data));

      if (res.code == 200) {
        this.sidebarList[index].children = res.data;
      } else {
        this.$message({
          message: res.message,
        });
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getCount();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.HomePage {
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -25px;
  // padding: 25px 0 0 0;
  background-color: #fbf8fb;

  .commonFooter_box {
    margin-top: 20px;
    width: 100%;
  }

  .box {
    width: 1350px;
    margin: 0 auto;

    // 搜索
    .search_box {
      // padding: 20px 30px 0;
      width: 646.2px;
      height: 47px;
      margin: 20px auto;
      background-color: #fff;
      .search {
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        // padding: 5px 5px 5px 0;

        border-radius: 5px;
        border: 1px solid #ff6900;

        .search_left {
          .el-select {
            ::v-deep .el-input {
              .el-input__inner {
                width: 100px;
                border: 0px;
              }
            }
          }
        }

        .inp {
          height: 26px;
          border-left: 1px solid #e4e4e4;
          padding-left: 20px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-gray;
          flex: 1;
        }
        .search_btn {
          // width: 40px;
          height: 40px;
          padding: 0 10px;
          background: #ff6900;

          @include flex-center();

          cursor: pointer;
          > img:first-child {
            width: 52px;
            height: 22px;
            margin: 0 10px;
          }
        }
      }

      .key_box {
        margin-top: 20px;
        display: flex;

        .key_item {
          font-size: 14px;
          color: #666666;
          margin-right: 20px;
          cursor: pointer;
        }

        .keyI {
          color: #ff6500;
        }
      }
    }

    .content_box {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      .left {
        background-color: #fff;
        margin-right: 10px;
        .top {
          display: flex;
          position: relative;

          .sidebar {
            width: 190px;
            height: 470px;
            overflow-x: hidden;
            overflow-y: auto;
            margin-right: 10px;
            background-color: #fff;

            box-sizing: border-box;
            padding-top: 30px;

            .title {
              position: absolute;
              top: 0px;

              width: 190px;
              box-sizing: border-box;
              // margin-bottom: 23px;
              padding: 10px 20px;
              border-left: 3px solid #ff6900;
              font-size: 14px;
              font-weight: bold;
              color: #000;
              z-index: 100;
              background-color: #fff;
            }

            &::-webkit-scrollbar {
              width: 3px;
            }

            ::v-deep .el-menu {
              border: 0px;
              .el-submenu__title {
                overflow: hidden;
                height: 30px;
                line-height: 30px;
              }
              .el-menu-item {
                height: 30px;
                line-height: 30px;
                overflow: hidden;
                box-sizing: border-box;
                min-width: 190px;
                width: 190px;
              }
            }
          }

          .banner {
            width: 790px;
            height: 470px;
            margin-right: 10px;
            background-color: #fff;

            .img {
              width: 790px;
              height: 470px;
            }
          }

          .video {
            width: 190px;
            height: 470px;
            overflow: auto;
            background-color: #fff;

            .text {
              margin-top: 20px;
              padding-left: 12px;
              border-left: 3px solid #ff6500;
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 12px;
            }

            .img_box {
              margin-left: 3px;
              width: 168px;
              margin-bottom: 20px;
              cursor: pointer;
              img {
                width: 100%;
              }
            }

            .video_item {
              padding: 20px;
              cursor: pointer;

              &:hover {
                background-color: #fffaf3;
              }
              img {
                width: 149px;
                height: 88px;
              }

              .video_title {
                margin: 17px 0 10px 0;

                font-size: 16px;
              }

              .video_related {
                margin-top: 10px;
                font-size: 12px;
                display: flex;
                justify-content: space-between;
                color: #666666;
                .heat {
                  display: flex;
                  align-items: center;
                  .heat_icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 10px;
                    background: url("~assets/images/info/hot.png") no-repeat;
                    background-size: 100% 100%;
                  }
                }

                .score {
                  display: flex;
                  align-items: center;
                  .score_icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 10px;
                    background: url("~assets/images/writing/score_yellow.png")
                      no-repeat;
                    background-size: 100% 100%;
                  }
                }

                .review {
                  display: flex;
                  align-items: center;
                  .score_icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 10px;
                    background: url("~assets/images/writing/review.png")
                      no-repeat;
                    background-size: 100% 100%;
                  }
                }
              }
            }
          }
        }

        .botton {
          margin-top: 10px;
          position: relative;

          img {
            width: 100%;
            height: 320px;
          }

          .btn {
            display: flex;
            position: absolute;
            bottom: 25px;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
            padding: 10px 20px;
            background: #ff6900;
            border-radius: 5px;
            color: #fff;
            font-family: PingFang SC;
            font-size: 18px;
            justify-content: center;
            align-items: center;

            img {
              width: 26px;
              height: auto;
              margin-right: 10px;
            }
          }
        }

        .info_box {
          .info_item {
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 69px 70px 60px 60px;
            margin-bottom: 10px;

            .left_box {
              width: 432.6px;
              box-sizing: border-box;
              padding: 64px 34px 48px 36px;
              box-shadow: 0px 0px 29px 0px rgba(158, 165, 195, 0.18);
              position: relative;

              .img {
                position: absolute;
                top: -30px;
                left: 0;
                width: 329px;
                height: 46px;
              }

              .title {
                font-size: 26px;
                font-weight: bold;
                margin-bottom: 30px;
              }

              .text {
                font-size: 16px;
                line-height: 30px;
              }
            }

            .right_box {
              width: 351px;
              position: relative;

              .Start {
                cursor: pointer;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 61px;
                height: 61px;
              }

              .img {
                width: 100%;
              }
            }
          }
        }
      }

      .right {
        width: 350px;
        background-color: #fff;

        .Recommend_text {
          margin-top: 20px;
          font-size: 14px;
          font-weight: bold;
          padding: 5px 10px;
          border-left: 3px solid #ff6900;
        }

        .article_box {
          margin-top: 15px;
          padding: 0 0 0 16px;
          .item {
            cursor: pointer;
            font-size: 14px;
            margin-bottom: 23px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            align-items: center;
            white-space: nowrap;

            &:hover {
              color: #ff6900;
            }
          }

          span {
            color: #4587ff;
          }

          .title {
            margin-bottom: 23px;
            font-size: 14px;
            font-weight: bold;
            color: #4587ff;
          }
        }
      }
    }
  }

  .data_box {
    display: flex;
    background-color: #f9f9fa;
    width: 1350px;
    margin: 10px auto;
    justify-content: space-between;
    .item_box {
      width: 443px;
      height: 320px;
      padding: 30px;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: #fff;
      position: relative;

      .name {
        font-size: 16px;

        font-weight: bold;
      }
      .article {
        margin: 25px 0;
        .count {
          font-size: 44px;
          color: #4587ff;
        }
        .unit {
          font-size: 16px;
          color: #999999;
        }
      }
      .infoCompile,
      .stencil,
      .goldenSentence {
        color: #1c1a1d;
        font-size: 16px;
        margin-bottom: 15px;
      }
      .dot {
        position: relative;
        display: flex;
        align-items: center;
      }
      .dot::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: #4587ff;
        border-radius: 50%;
        margin-right: 10px;
        // position: absolute;
        // left: -20px;
        // top: 50%;
        // transform: translateY(-50%);
      }

      .bg {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 148px;
      }
    }
  }
}
</style>
